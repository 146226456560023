import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaChevronLeft, FaChevronRight, FaPlay, FaPause } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const CarouselContainer = styled.section`
  position: relative;
  width: 100%;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.95));
  padding: 20px;
`;

const CarouselInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  role: "region";
  aria-label: "Image carousel";
`;

const CarouselTrack = styled(motion.div)`
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const CarouselSlide = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 2px solid transparent;
  background: linear-gradient(145deg, #1a1a1a, #0a0a0a);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(
      145deg,
      var(--dragon-orange-primary),
      var(--dragon-orange-secondary)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    opacity: 0.5;
    transition: opacity 0.4s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  role: "group";
  aria-label: "Carousel controls";
`;

const ControlButton = styled.button.attrs(props => ({
  'aria-label': props['aria-label'],
  type: 'button'
}))`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 77, 0, 0.2);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 77, 0, 0.4);
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      transform: none;
    }
  }
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--dragon-orange-primary),
    var(--dragon-orange-secondary)
  );
  width: ${props => (props.progress * 100)}%;
  transition: width 0.3s linear;
`;

const Thumbnails = styled.nav`
  display: flex;
  gap: 10px;
  padding: 10px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  role: "navigation";
  aria-label: "Image thumbnails";
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Thumbnail = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid ${props => props.active ? 'var(--dragon-orange-primary)' : 'transparent'};
  opacity: ${props => props.active ? 1 : 0.6};
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FullScreenModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 40px;
`;

const FullScreenImage = styled(motion.img)`
  max-width: 95%;
  max-height: 95vh;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
`;

const ImageCaption = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-size: 1rem;
  white-space: nowrap;
`;

const ImageCounter = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.8);
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  font-size: 0.9rem;
`;

const MemoizedThumbnail = memo(({ image, active, onClick, index, total }) => (
  <Thumbnail 
    active={active} 
    onClick={onClick}
    role="button"
    aria-label={`View image ${index + 1} of ${total}: ${image.alt}`}
    aria-pressed={active}
  >
    <img 
      src={image.url} 
      alt={image.alt} 
      loading="lazy"
      width="60"
      height="60"
    />
  </Thumbnail>
));

const ImageCarousel = memo(({ images, title = '', description = 'Professional Audio and Light Solutions for events across USA' }) => {
  console.log('ImageCarousel images:', images); // Debug log
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef(null);
  const slideInterval = useRef(null);
  const SLIDE_DURATION = 5000;

  // Ensure images array is not empty and contains valid URLs
  const validImages = Array.isArray(images) ? images.filter(img => img && img.url) : [];
  const currentImage = validImages[currentIndex] || { url: '', alt: '' };

  const clearIntervals = useCallback(() => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
      progressInterval.current = null;
    }
    if (slideInterval.current) {
      clearInterval(slideInterval.current);
      slideInterval.current = null;
    }
  }, []);

  const startSlideShow = useCallback(() => {
    clearIntervals();
    setIsPlaying(true);
    let startTime = Date.now();

    progressInterval.current = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const newProgress = elapsed / SLIDE_DURATION;
      if (newProgress >= 1) {
        startTime = Date.now();
        setProgress(0);
      } else {
        setProgress(newProgress);
      }
    }, 32);

    slideInterval.current = setInterval(() => {
      setCurrentIndex(prev => (prev + 1) % images.length);
      startTime = Date.now();
      setProgress(0);
    }, SLIDE_DURATION);
  }, [images.length]);

  const stopSlideShow = useCallback(() => {
    setIsPlaying(false);
    clearIntervals();
  }, [clearIntervals]);

  useEffect(() => {
    startSlideShow();
    return clearIntervals;
  }, [startSlideShow, clearIntervals]);

  const handlePrevious = useCallback(() => {
    stopSlideShow();
    setCurrentIndex(prev => (prev - 1 + images.length) % images.length);
  }, [stopSlideShow, images.length]);

  const handleNext = useCallback(() => {
    stopSlideShow();
    setCurrentIndex(prev => (prev + 1) % images.length);
  }, [stopSlideShow, images.length]);

  const handleThumbnailClick = useCallback((index) => {
    stopSlideShow();
    setCurrentIndex(index);
  }, [stopSlideShow]);

  const togglePlayPause = useCallback(() => {
    if (isPlaying) {
      stopSlideShow();
    } else {
      startSlideShow();
    }
  }, [isPlaying, stopSlideShow, startSlideShow]);

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0
    })
  };

  // Format the page title correctly with the default title
  const defaultTitle = 'Phoenix Global Solutions - Professional Audio and Light Solutions in USA';
  const pageTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const pageDescription = description || 'Professional audio and light solutions for events across USA. Providing top-quality sound and lighting equipment for all types of events.';

  // Update metadata with more specific information
  const imageMetadata = {
    '@context': 'https://schema.org',
    '@type': 'ImageGallery',
    name: pageTitle,
    description: pageDescription,
    provider: {
      '@type': 'Organization',
      name: 'Phoenix Global Solutions',
      description: 'Professional Audio and Light Solutions in USA'
    },
    image: images.map(img => ({
      '@type': 'ImageObject',
      contentUrl: img.url,
      description: img.alt,
      caption: img.alt
    }))
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:site_name" content="Phoenix Global Solutions" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {currentImage && (
          <meta property="og:image" content={currentImage.url} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        {currentImage && (
          <meta name="twitter:image" content={currentImage.url} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(imageMetadata)}
        </script>
      </Helmet>

      <CarouselContainer role="region" aria-label={title}>
        <h2 className="visually-hidden">{title}</h2>
        <CarouselInner>
          <CarouselTrack>
            <AnimatePresence initial={false} custom={currentIndex} mode="wait">
              <CarouselSlide
                key={currentIndex}
                custom={currentIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 }
                }}
                onClick={() => setFullScreenImage(currentImage)}
                role="button"
                aria-label={`View ${currentImage.alt} in full screen`}
              >
                <CarouselImage 
                  src={currentImage.url} 
                  alt={currentImage.alt}
                  loading="eager"
                  width="800"
                  height="600"
                />
                <ImageCounter aria-live="polite">
                  {`Image ${currentIndex + 1} of ${images.length}`}
                </ImageCounter>
              </CarouselSlide>
            </AnimatePresence>
          </CarouselTrack>

          <Controls>
            <ControlButton 
              onClick={handlePrevious} 
              disabled={images.length <= 1}
              aria-label="Previous image"
            >
              <FaChevronLeft />
            </ControlButton>
            
            <ControlButton 
              onClick={togglePlayPause}
              aria-label={isPlaying ? "Pause slideshow" : "Play slideshow"}
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </ControlButton>

            <Progress role="progressbar" aria-valuenow={progress * 100} aria-valuemin="0" aria-valuemax="100">
              <ProgressBar progress={progress} />
            </Progress>

            <ControlButton 
              onClick={handleNext} 
              disabled={images.length <= 1}
              aria-label="Next image"
            >
              <FaChevronRight />
            </ControlButton>
          </Controls>

          <Thumbnails>
            {images.map((image, index) => (
              <MemoizedThumbnail
                key={index}
                image={image}
                active={index === currentIndex}
                onClick={() => handleThumbnailClick(index)}
                index={index}
                total={images.length}
              />
            ))}
          </Thumbnails>
        </CarouselInner>
      </CarouselContainer>

      <AnimatePresence>
        {fullScreenImage && (
          <FullScreenModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setFullScreenImage(null)}
            role="dialog"
            aria-label="Full screen image view"
          >
            <FullScreenImage
              src={fullScreenImage.url}
              alt={fullScreenImage.alt}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
              loading="eager"
              width="1200"
              height="800"
            />
            <ControlButton 
              style={{ position: 'absolute', top: 20, right: 20 }}
              onClick={() => setFullScreenImage(null)}
              aria-label="Close full screen view"
            >
              <FaTimes />
            </ControlButton>
          </FullScreenModal>
        )}
      </AnimatePresence>

      <style>
        {`
          .visually-hidden {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
          }
        `}
      </style>
    </>
  );
});

ImageCarousel.displayName = 'ImageCarousel';
MemoizedThumbnail.displayName = 'MemoizedThumbnail';

export default ImageCarousel; 