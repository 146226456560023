import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ImageCarousel from './ImageCarousel';

const PortfolioSection = styled.section`
  padding: 100px 20px;
  background: linear-gradient(
    180deg,
    var(--dragon-black) 0%,
    var(--dragon-gray) 100%
  );
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      var(--dragon-orange-primary) 50%,
      transparent 100%
    );
    box-shadow: 0 0 20px var(--dragon-orange-primary);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 70px;
  font-size: 3.5rem;
  background: linear-gradient(135deg, var(--dragon-orange-primary), var(--dragon-orange-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(
      90deg,
      transparent,
      var(--dragon-orange-primary),
      transparent
    );
    box-shadow: 0 0 10px var(--dragon-orange-primary);
  }
`;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const Event = styled(motion.div)`
  background: linear-gradient(
    145deg,
    var(--dragon-gray) 0%,
    var(--dragon-black) 100%
  );
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  border: 1px solid var(--dragon-orange-primary);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      var(--dragon-orange-primary),
      transparent
    );
    box-shadow: 0 0 10px var(--dragon-orange-primary);
  }

  &:hover {
    transform: translateY(-5px);
    border-color: var(--dragon-orange-secondary);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;
  }
`;

const EventTitle = styled.h3`
  font-size: 2.8rem;
  background: linear-gradient(135deg, var(--dragon-orange-primary), var(--dragon-orange-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: center;
`;

const EventDescription = styled.div`
  color: var(--text-secondary);
  margin-bottom: 40px;
  line-height: 1.8;
  font-size: 1.2rem;
  text-align: center;

  p {
    margin: 20px 0;
  }
`;

const Portfolio = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://phoenixssolutions.com/evdashboard/get_events.php');
        const data = await response.json();
        
        if (data.success) {
          const processedEvents = data.events.map(event => ({
            ...event,
            images: event.images.map(img => ({
              url: img.url.replace('http://localhost/admin-dashboard/', 'https://phoenixssolutions.com/evdashboard/'),
              alt: img.alt
            }))
          }));
          console.log('Processed events:', processedEvents);
          setEvents(processedEvents);
        } else {
          setError(data.error || 'Failed to fetch events');
        }
      } catch (err) {
        setError('Error connecting to the server');
        console.error('Error fetching events:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <PortfolioSection id="portfolio">
        <Container>
          <Title>Featured Events</Title>
          <div style={{ textAlign: 'center', color: 'white' }}>Loading events...</div>
        </Container>
      </PortfolioSection>
    );
  }

  if (error) {
    return (
      <PortfolioSection id="portfolio">
        <Container>
          <Title>Featured Events</Title>
          <div style={{ textAlign: 'center', color: '#ff4444' }}>{error}</div>
        </Container>
      </PortfolioSection>
    );
  }

  return (
    <PortfolioSection id="portfolio">
      <Container>
        <Title>Featured Events</Title>
        <EventsContainer>
          {events.map((event, index) => (
            <Event
              key={event.id}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <EventTitle>{event.title}</EventTitle>
              <EventDescription>
                <p>{event.description}</p>
              </EventDescription>
              <ImageCarousel images={event.images} />
            </Event>
          ))}
        </EventsContainer>
      </Container>
    </PortfolioSection>
  );
};

export default Portfolio; 