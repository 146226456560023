import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --dragon-black: #0A0A0A;
    --dragon-orange-primary: #FF4D00;
    --dragon-orange-secondary: #FF8C00;
    --dragon-gray: #1E1E1E;
    --text: #FFFFFF;
    --text-secondary: rgba(255, 255, 255, 0.8);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text);
    background: var(--dragon-black);
    transition: all 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--text);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  a {
    color: var(--dragon-orange-primary);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--dragon-orange-secondary);
      text-shadow: 0 0 10px rgba(255, 77, 0, 0.5);
    }
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: linear-gradient(135deg, var(--dragon-orange-primary), var(--dragon-orange-secondary));
    color: var(--text);
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    &:hover {
      background: linear-gradient(135deg, var(--dragon-orange-secondary), var(--dragon-orange-primary));
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }
`;

export default GlobalStyles; 